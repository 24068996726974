import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId[// ETHEREUM = 1,
    // RINKEBY = 4,
    // GOERLI = 5,
    "BSC"] = 1] = "BSC";
    ChainId[ChainId["BASE"] = 8453] = "BASE";
    ChainId[ChainId[// STEALTH_TESTNET = 44474478
    "STEALTH_TESTNET"] = 5] = "STEALTH_TESTNET";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.BSC, FACTORY_ADDRESS), _define_property(_obj, ChainId.BASE, "0x36F5f98fA5d70BfFe5D847aFdb5a779Eb8aa35E8"), _define_property(_obj, ChainId.STEALTH_TESTNET, "0x146B07Ad3cF07FC96d01EAD8E4b388E0c4BD3518"), _obj);
export var INIT_CODE_HASH = "0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.BSC, INIT_CODE_HASH), _define_property(_obj1, ChainId.BASE, "0x2bbd7558cce4e3e5f13b6e9975dd8c5f658e463e8dd824f47cab380798fcd134"), _define_property(_obj1, ChainId.STEALTH_TESTNET, "0xd8f2610c08abfab7bf42ae9021bb9de38e3092496017bb1a0660ce3b1571eac5"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var FEES_NUMERATOR = JSBI.BigInt(9975);
export var FEES_DENOMINATOR = JSBI.BigInt(10000);
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
